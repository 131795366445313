import { Grid } from "@hexa-ui/components";
import { QueryClientProvider } from "@tanstack/react-query";

import { EnvConfig, EnvProvider } from "./components";
import { queryClient } from "./config/queryClient";
import { ClusterProvider } from "./context/ClusterContext/ClusterContext";
import { RuleDataProvider } from "./context/RulesDetailsContext/RuleDetailsContext";
import { ThemeDataProvider } from "./context/ThemeDetailsContext/ThemeDetailsContext";
import { IntlProvider } from "./i18n";
import Router from "./Router";

export default function App(props: Readonly<EnvConfig>) {
  return (
    <EnvProvider env={props}>
      <IntlProvider>
        <QueryClientProvider client={queryClient}>
          <ClusterProvider>
            <ThemeDataProvider>
              <RuleDataProvider>
                <Grid.Container
                  style={{
                    rowGap: "1rem",
                    display: "block",
                  }}
                  type="fluid"
                  sidebar
                >
                  <Router />
                </Grid.Container>
              </RuleDataProvider>
            </ThemeDataProvider>
          </ClusterProvider>
        </QueryClientProvider>
      </IntlProvider>
    </EnvProvider>
  );
}
