import { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Loading from "./components/Loading/Loading";
import { MFE_ROUTES } from "./Constants";
import useAppSidebar from "./hooks/useAppSidebar/useAppSidebar";
import useUserGroups from "./hooks/useUserGroups/useUserGroups";

const HomePage = lazy(() => import("./pages/HomePage/HomePage"));
const CreateWorkspacePage = lazy(
  () => import("./pages/CreateWorkspacePage/CreateWorkspacePage")
);
const WorkspaceEdit = lazy(() => import("./pages/WorkspaceEdit/WorkspaceEdit"));
const ThemeDetails = lazy(() => import("./pages/ThemeDetails/ThemeDetails"));
const ReportsDetailsPage = lazy(
  () => import("./pages/ReportsDetailsPage/ReportsDetailsPage")
);

export const RouterContent = () => {
  useAppSidebar();

  const { isLoading } = useUserGroups();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path={MFE_ROUTES.HOME}>
          <Route index element={<HomePage />} />
          <Route
            path={MFE_ROUTES.CREATE_WORKSPACE}
            element={<CreateWorkspacePage />}
          />
          <Route path={MFE_ROUTES.WORKSPACE} element={<WorkspaceEdit />} />
          <Route path={MFE_ROUTES.THEME_DETAILS} element={<ThemeDetails />} />
          <Route
            path={`${MFE_ROUTES.REPORT_DETAILS}`}
            element={<ReportsDetailsPage />}
          />
        </Route>
      </Routes>
    </Suspense>
  );
};

const Router = () => {
  return (
    <BrowserRouter>
      <RouterContent />
    </BrowserRouter>
  );
};

export default Router;
