import { useUserMetadata } from "admin-portal-shared-services";

interface UserGroupsReturn {
  isLoading: boolean;
  userGroups: string[] | undefined;
}

export default function useUserGroups(): UserGroupsReturn {
  const userMetadata = useUserMetadata();

  return {
    isLoading: userMetadata.isLoading,
    userGroups: userMetadata.data?.authorization?.groups,
  };
}
