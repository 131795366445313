import { styled } from "@hexa-ui/theme";

const Container = styled("div", { paddingTop: "128px" });

const BuzzLoading = styled("div", {
  display: "flex",
  justifyContent: "center",
  marginBottom: "16px",
});

const LoadingTitle = styled("div", {
  marginBottom: "8px",
});

export const Styled = {
  Container,
  BuzzLoading,
  LoadingTitle,
};
