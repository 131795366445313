import { createContext, useContext, useMemo, useState } from "react";
import { Permission } from "@/domain/WorkspaceDetails";

const defaultRuleData: Permission[] = [];

const RuleContext = createContext<{
  ruleData: Permission[];
  setRuleData: React.Dispatch<React.SetStateAction<Permission[]>>;
}>({
  ruleData: defaultRuleData,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setRuleData: () => {},
});

export const RuleDataProvider: React.FC = ({ children }) => {
  const [ruleData, setRuleData] = useState<Permission[]>(defaultRuleData);

  const ruleDataValue = useMemo(() => ({ ruleData, setRuleData }), [ruleData]);

  return (
    <RuleContext.Provider value={ruleDataValue}>
      {children}
    </RuleContext.Provider>
  );
};

export const useRuleData = (): {
  ruleData: Permission[];
  setRuleData: React.Dispatch<React.SetStateAction<Permission[]>>;
} => {
  return useContext(RuleContext);
};
