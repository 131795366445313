import { useSidebar } from 'admin-portal-shared-services';

import { useSidebarItems } from './useSidebarItems';

const useAppSidebar = () => {
  useSidebar({
    items: useSidebarItems(),
    utils: [],
  });
};

export default useAppSidebar;
