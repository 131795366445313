import { useMemo } from "react";

import { Grid } from "@hexa-ui/icons";
import { SidebarItem } from "admin-portal-shared-services";

import { MFE_ROUTES } from "@/Constants";

/* istanbul ignore next */
const GridIcon = (): React.ReactElement => <Grid />;

export const useSidebarItems = (): SidebarItem[] => {
  return useMemo(() => {
    const items: SidebarItem[] = [];

    items.push({
      id: "reportsMainPage",
      icon: GridIcon,
      path: MFE_ROUTES.REPORTS_MFE_MAIN_PAGE,
      title: "Main page",
    });

    return items;
  }, []);
};
