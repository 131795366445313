import { createContext, useContext, useMemo, useState } from "react";

import { ReportGroup } from "@/domain/WorkspaceDetails";

const defaultThemeData: ReportGroup[] = [];

const ThemeContext = createContext<{
  themeData: ReportGroup[];
  setThemeData: React.Dispatch<React.SetStateAction<ReportGroup[]>>;
}>({
  themeData: defaultThemeData,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setThemeData: () => {},
});

export const ThemeDataProvider: React.FC = ({ children }) => {
  const [themeData, setThemeData] = useState<ReportGroup[]>(defaultThemeData);

  const themeDataValue = useMemo(
    () => ({ themeData, setThemeData }),
    [themeData]
  );

  return (
    <ThemeContext.Provider value={themeDataValue}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useThemeData = (): {
  themeData: ReportGroup[];
  setThemeData: React.Dispatch<React.SetStateAction<ReportGroup[]>>;
} => {
  return useContext(ThemeContext);
};
