import { QueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { StatusCode } from "../domain/StatusCode";

const retryStatusCodes = [
  StatusCode.REQUEST_TIMEOUT,
  StatusCode.PAYLOAD_TOO_LARGE,
  StatusCode.TOO_MANY_REQUESTS,
  StatusCode.INTERNAL_SERVER_ERROR,
  StatusCode.BAD_GATEWAY,
  StatusCode.SERVICE_UNAVALIABLE,
  StatusCode.GATEWAY_TIMEOUT,
];

const MAX_RETRIES = 2;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      retry: (retryCount, error) => {
        const axiosError = error as AxiosError;

        if (retryCount >= MAX_RETRIES) return false;

        if (!axiosError?.response) return false;

        return retryStatusCodes.includes(axiosError.response.status);
      },
    },
  },
});
