import React from "react";
import { useIntl } from "react-intl";

import { Error500, Heading, LoadingBuzz, Paragraph } from "@hexa-ui/components";

import { Styled } from "./Loading.style";
import { ILoadingProps } from "./Loading.types";

const Loading = ({
  children,
  isLoading = true,
  isLoadingError = false,
  title,
  body,
}: ILoadingProps): React.ReactElement => {
  const { formatMessage } = useIntl();

  const LoadingContent = (
    <div>
      <Styled.BuzzLoading data-testid="loadingBuzz">
        <LoadingBuzz color="mono" size="xxlarge" />
      </Styled.BuzzLoading>
      <Styled.LoadingTitle data-testid="loadingBuzzTitle">
        <Heading size="H5" alignment="center" data-testid="loadingTitle">
          {title || formatMessage({ id: "LOADING.TITLE" })}
        </Heading>
      </Styled.LoadingTitle>
      <Paragraph alignment="center" size="basis" data-testid="loadingParagraph">
        {body || formatMessage({ id: "LOADING.PARAGRAPH" })}
      </Paragraph>
    </div>
  );

  if (isLoading) {
    return <Styled.Container>{LoadingContent}</Styled.Container>;
  }

  if (isLoadingError) {
    return (
      <Styled.Container data-testid={"loading-error"}>
        <Error500 headerText={formatMessage({ id: "LOADING.LOADING_ERROR" })} />
      </Styled.Container>
    );
  }

  return <>{children}</>;
};

export default Loading;
