import MessageMap from "../i18n.d";

const enUS: MessageMap = {
  test: {
    translate: "Translate",
    missing: "Only English option available",
  },
  BREADCRUMB: {
    WORKSPACES: "Workspaces",
  },
  ACTIVATION_PAGE: {
    CLUSTER_SELECT: {
      LABEL: "Select a cluster",
      GLOBAL_US: "Global US",
      GLOBAL_EU: "Global EU",
      SELECTED_CLUSTER: "Selected cluster",
    },
  },
  HOME_PAGE: {
    TITLE: "Data & Analytics Admin",
  },
  CREATE_WORKSPACE_PAGE: {
    PAGE_TITLE: "Add new workspace",
    CREATE_BUTTON: "Create",
    CANCEL_BUTTON: "Cancel",
    WORKSPACE_CREATED:
      "Workspace created succesfully. You can add themes, reports and access rules.",
    WORKSPACE_NOT_CREATED: "Workspace was not created. Please try again.",
    THEME_SECTION: {
      TITLE: "Themes",
      SPAN: "(Optional)",
      DESCRIPTION:
        "These themes will be used to create the UX menu navigation options.",
      EMPTY_STATE_TEXT: "No themes added",
      CONFIRMATION_BUTTON: "Add theme",
      THEME_MODAL: {
        ADD_TITLE: "Add theme",
        EDIT_TITLE: "Edit theme",
        LANGUAGE_SUBTITLE: "Multi-Language Title Configuration",
        ENGLISH_LABEL: "English",
        SPANISH_LABEL: "Spanish",
        PORTUGUESE_LABEL: "Portuguese",
        BAHASA_LABEL: "Bahasa",
        EN_NAME_PLACEHOLDER: "Enter a US name",
        ES_NAME_PLACEHOLDER: "Enter a ES name",
        ID_NAME_PLACEHOLDER: "Enter a ID name",
        PT_NAME_PLACEHOLDER: "Enter a PT name",
        TRACKINGID_PLACEHOLDER: "Enter a tracking ID",
        ORDER_PLACEHOLDER: "Enter a order",
        THEME_SUBTITLE: "Theme information",
        ICON_LABEL: "Icon",
        FLAG_LABEL: "Flag",
        COUNTRY_VENDOR_LABEL: "Country",
        STRUCTURE_LABEL: "Structure",
        TRACKINGID_LABEL: "TrackingID",
        TRACKINGID_SPAN:
          "Use all caps, underscores for spaces, no special characters.",
        THEME_ORDER_LABEL: "Theme order",
        CONFIRM_ADD_BUTTON: "Add",
        CONFIRM_EDIT_BUTTON: "Save",
        CANCEL_BUTTON: "Cancel",
        SELECT_PLACEHOLDER: "Select",
        DELETE_TOAST: "Theme deleted succesfully.",
      },
      DELETE: {
        TITLE: "Delete theme",
        DESCRIPTION:
          "Are you sure you want to delete this theme? All reports within this theme will also be deleted. This action cannot be undone.",
      },
    },
    DELETE_WORKSPACES_TOAST: {
      SUCCESS: "Workspace deleted succesfully.",
      ERROR: "Something went wrong. Please try again later.",
    },
    DELETE_DESTRUCTIVE_MODAL: {
      TITLE: "Delete workspace",
      DESCRIPTION:
        "Are you sure you want to delete this workspace? All content within this workspace will also be deleted. This action cannot be undone.",
    },
    BUTTONS: {
      GOBACK: "Go back",
      DELETE: "Delete",
    },
  },
  WORKSPACES: {
    TITLE: "Workspaces",
    ADD_NEW: "Add new",
    PAGE_TITLE: "Data & Analytics Admin",
    EMPTY_WORKSPACES: "No Workspaces available, start by creating one",
    CARD: {
      ACCESS_RULES: "Access rules",
      USER: "User",
      USERS: "Users",
    },
  },
  LOADING: {
    TITLE: "Loading",
    PARAGRAPH: "We are preparing the page for you.",
    LOADING_ERROR: "Ooops! Something went wrong",
  },
  UNAUTHORIZED: {
    ERROR_TITLE: "Hmm… Access denied!",
    ERROR_DESCRIPTION:
      "It looks like you don’t have permission to access this page.",
  },
  DELETE_MODAL: {
    CONFIRM_BUTTON: "Delete",
    CANCEL_BUTTON: "Cancel",
  },
  INPUT: {
    REQUIRED: "Information required",
    PATTERN_INVALID: "The input doesn't match the requirements.",
    TRACKINGID_ERROR: "TrackingId already in use.",
  },
  TOAST: {
    SUCCESS: "Success! Your changes have been saved",
    ERROR: "Something went wrong. Please try again later",
  },
  SECTION_CARD: {
    EDIT: "Edit",
  },
  TITLES_SECTION: {
    SECTION_TITLE: "Multi-Language title configuration",
    SECTION_SUBTITLE: "Set the workspace title in multiple languages.",
    LANGUAGE: "Language",
    TITLE: "Title",
    ENTER_TITLE: "Enter title",
    ENGLISH: "English",
    SPANISH: "Spanish",
    PORTUGUESE: "Portuguese",
    BAHASA: "Bahasa",
    CANCEL: "Cancel",
    SAVE: "Save",
  },
  TRACKING_ID_SECTION: {
    TITLE: "TrackingID",
    SUBTITLE:
      "Set the TrackingID for the workspace using all caps, underscores for spaces and no special characters. This field can not be edited after creation.",
    PLACEHOLDER: "Enter trackingID",
    ERROR: "TrackingId already in use.",
  },
  ROW_LEVEL_SECURITY_SECTION: {
    TITLE: "Row level security",
    SUBTITLE: "Set the Row level security rules for the workspace.",
    ROLE: {
      PLACEHOLDER: "Enter role",
      LABEL: "Role",
    },
    SCHEMA: {
      PLACEHOLDER: "Select",
      LABEL: "Schema",
    },
  },
  PERMISSION_GROUP_SECTION: {
    TITLE: "Permission Group",
    SUBTITLE: "Select the permission group for the workspace.",
    PLACEHOLDER: "Select",
  },
  ACCESS_RULES_SECTION: {
    TITLE: "Access rules",
    SPAN: "(Optional)",
    DESCRIPTION: "Set the access rules for the workspace.",
    EMPTY_STATE_TEXT: "No rules added",
    CONFIRMATION_BUTTON: "Add rule",
    RULE_MODAL: {
      ADD_TITLE: "Add rule",
      EDIT_TITLE: "Edit rule",
      RULE_LABEL: "Rule name",
      RULE_PLACEHOLDER: "Enter hint text",
      ICON_LABEL: "Icon",
      RULE_TYPE_LABEL: "Rule type",
      RULE_TYPE_PLACEHOLDER: "Select",
      RULE_VALUE_LABEL: "Value",
      RULE_VALUE_PLACEHOLDER: "Enter rule",
      CONFIRM_ADD_BUTTON: "Add",
      CONFIRM_EDIT_BUTTON: "Save",
      CANCEL_BUTTON: "Cancel",
      SELECT_PLACEHOLDER: "Select",
      DELETE_TOAST: "Rule deleted succesfully.",
      IMAGE_LABEL: "Image URL",
      IMAGE_PLACEHOLDER: "Enter image URL",
      THUMBNAIL_LABEL: "Thumbnail type",
      ADD_TOAST: "Rule added succesfully.",
    },
    DELETE: {
      TITLE: "Delete rule",
      DESCRIPTION:
        "Are you sure you want to delete this rule? This action cannot be undone.",
    },
  },
  THEMES_DETAILS_PAGE: {
    THEME_INFORMATION: "Theme information",
    THEME_NAME: "Theme name",
    STRUCTURE: "Structure",
    REPORTS_SECTION: {
      TITLE: "Reports",
      DESCRIPTION: "Add reports to be displayed inside the themes.",
      EMPTY_STATE_TEXT: "No data available",
      CONFIRMATION_BUTTON: "Add report",
      DELETE_TOAST_SUCCESS: "Report deleted successfully.",
    },
    DELETE_MODAL: {
      TITLE: "Delete report",
      DESCRIPTION:
        "Are you sure you want to delete this report? This action cannot be undone.",
    },
    TRACKING_ID: "TrackingID",
    ORDER: "Theme order",
  },
  REPORT_DETAILS_PAGE: {
    HEADER: {
      TITLE: "Title",
      TRACKING_ID: "Tracking ID",
      TAB_ORDER: "Tab order",
      WORKSPACE_ID: "Workspace ID",
      REPORT_ID: "Report ID",
      REPORT_INFORMATION: "Report information",
      EDIT_BUTTON: "Edit",
    },
    REPORT_MODAL: {
      SECTION_TITLE: "Multi-Language title configuration",
      REPORT_INFORMATION_SECTION: "Report information",
      POWERBI_SECTION: "PowerBI parameters",
      SECTION_SUBTITLE: "Set the workspace title in multiple languages.",
      LANGUAGE: "Language",
      TITLE: "Title",
      ENTER_TITLE: "Enter title",
      ENGLISH: "English",
      SPANISH: "Spanish",
      PORTUGUESE: "Portuguese",
      BAHASA: "Bahasa",
      CANCEL: "Cancel",
      SAVE: "Save",
      ADD: "Add",
      EDIT_REPORT: "Edit report",
      ADD_REPORT: "Add reports",
      TRACKING_ID: "Tracking ID",
      TRACKING_ID_HINT:
        "Use all caps, underscores for spaces, no special characters.",
      WORKSPACE_ID: "Workspace ID",
      REPORT_ID: "Report ID",
      TAB_ORDER: "Tab order",
      TAB_ORDER_PLACEHOLDER: "Enter an Tab order",
    },
    TABLE: {
      TITLE: "Access rules",
      SUBTITLE: "Access rules added for this workspace.",
      ACCESS_RULE: "Access management rule",
      STATE: "State",
      NO_DATA: "Sorry, no results found.",
      SUCCESS: "Access rule updated successfully.",
      FAIL: "Access rule updated fail.",
      LOADING: "Loading items",
      ACTIVE: "Active",
      INACTIVE: "Inactive",
    },
  },
};

export default enUS;
